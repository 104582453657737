// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-calendar {
    background: #333333; /* Dark background */
    color: #ffffff; /* Light text */
    border: none;
    transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
  }
  
  .react-calendar__navigation {
    background: #222222; /* Slightly darker background for the header */
    color: #ffffff;
  }
  
  .react-calendar__navigation button {
    color: #ffffff;
  }
  
  .react-calendar__month-view__days__day {
    color: #ffffff;
  }
  
  .react-calendar__tile {
    background: #444444;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    transition: background-color 0.2s, color 0.2s; /* Smooth transitions */
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #555555; /* Lighter dark shade for hover/focus */
    color: #ffffff;
    outline: none; /* Remove focus outline if not desired */
  }
  
  .react-calendar__tile--now {
    background: #666666; /* Different color for the current day */
  }
  
  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #007acc; /* Grafana blue for the selected day */
    color: #ffffff;
    outline: none;
  }
  `, "",{"version":3,"sources":["webpack://./components/reactCalendarCustomStyles.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB,EAAE,oBAAoB;IACzC,cAAc,EAAE,eAAe;IAC/B,YAAY;IACZ,6CAA6C,EAAE,uBAAuB;EACxE;;EAEA;IACE,mBAAmB,EAAE,8CAA8C;IACnE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,6CAA6C,EAAE,uBAAuB;EACxE;;EAEA;;IAEE,yBAAyB,EAAE,uCAAuC;IAClE,cAAc;IACd,aAAa,EAAE,wCAAwC;EACzD;;EAEA;IACE,mBAAmB,EAAE,wCAAwC;EAC/D;;EAEA;;;IAGE,mBAAmB,EAAE,sCAAsC;IAC3D,cAAc;IACd,aAAa;EACf","sourcesContent":[".react-calendar {\n    background: #333333; /* Dark background */\n    color: #ffffff; /* Light text */\n    border: none;\n    transition: background-color 0.3s, color 0.3s; /* Smooth transitions */\n  }\n  \n  .react-calendar__navigation {\n    background: #222222; /* Slightly darker background for the header */\n    color: #ffffff;\n  }\n  \n  .react-calendar__navigation button {\n    color: #ffffff;\n  }\n  \n  .react-calendar__month-view__days__day {\n    color: #ffffff;\n  }\n  \n  .react-calendar__tile {\n    background: #444444;\n    color: #ffffff;\n    border-radius: 4px;\n    border: none;\n    transition: background-color 0.2s, color 0.2s; /* Smooth transitions */\n  }\n  \n  .react-calendar__tile:enabled:hover,\n  .react-calendar__tile:enabled:focus {\n    background-color: #555555; /* Lighter dark shade for hover/focus */\n    color: #ffffff;\n    outline: none; /* Remove focus outline if not desired */\n  }\n  \n  .react-calendar__tile--now {\n    background: #666666; /* Different color for the current day */\n  }\n  \n  .react-calendar__tile--active,\n  .react-calendar__tile--active:enabled:hover,\n  .react-calendar__tile--active:enabled:focus {\n    background: #007acc; /* Grafana blue for the selected day */\n    color: #ffffff;\n    outline: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
