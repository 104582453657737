import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DateTime, PanelProps, dateTime } from '@grafana/data';
import { TimeOfDayPicker, IconButton, Button, HorizontalGroup, VerticalGroup, MultiSelect } from '@grafana/ui';
import { css } from '@emotion/css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './reactCalendarCustomStyles.css';
import { Shift } from 'types';
import { locationService } from '@grafana/runtime';
import { saveAs } from 'file-saver';
import * as Papa from 'papaparse';

interface Props extends PanelProps<any> {
  onChangeTimeRange: (range: { from: number; to: number }) => void;
  replaceVariables: (value: string) => string;
}

interface DataPoint {
  timestamp: number;
  value: number;
}

interface GroupedData {
  [date: string]: {
    aboveThreshold: number;
    total: number;
  };
}

const daysOfWeekOptions = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 },
];

export const SimplePanel: React.FC<Props> = ({
  options,
  onOptionsChange,
  data,
  width,
  height,
  fieldConfig,
  id,
  onChangeTimeRange,
  replaceVariables,
}) => {
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
  const [shifts, setShifts] = useState<Shift[]>(
    options.shifts && options.shifts.length > 0
      ? options.shifts.map((shift: Shift) => ({
          ...shift,
          daysOfWeek: shift.daysOfWeek || [],
        }))
      : [
          {
            id: 0,
            startTime: dateTime(),
            endTime: dateTime().add(8, 'hours'),
            daysOfWeek: [],
          },
        ]
  );

  const previousExcludedDaysRef = useRef<string>('');
  const previousShiftTimesRef = useRef<{ [key: number]: { startTime: number; endTime: number } }>({});
  const previousShiftsRef = useRef<Shift[]>(shifts);

  const updateExcludedDaysVariable = (shifts: Shift[]) => {
    const excludedDaysArray = shifts.flatMap(shift => shift.daysOfWeek);
    const excludedDays = excludedDaysArray.length > 0 ? excludedDaysArray.join(',') : '8';
    
    if (previousExcludedDaysRef.current !== excludedDays) {
      locationService.partial({ 'var-excluded_days': excludedDays }, true);
      previousExcludedDaysRef.current = excludedDays;
    }
  };

  const updateVariables = useCallback((shift: Shift) => {
    const startTime = convertTo24HourNumber(dateTime(shift.startTime));
    const endTime = convertTo24HourNumber(dateTime(shift.endTime));
    
    if (
      previousShiftTimesRef.current[shift.id]?.startTime !== startTime ||
      previousShiftTimesRef.current[shift.id]?.endTime !== endTime
    ) {
      locationService.partial({
        'var-shift_start': startTime.toString(),
        'var-shift_end': endTime.toString(),
      }, true);
      previousShiftTimesRef.current[shift.id] = { startTime, endTime };
    }
  }, []);

  useEffect(() => {
    if (shifts.length > 0) {
      const maxId = Math.max(...shifts.map((s) => s.id)) + 1;
      if (maxId !== shifts.length) {
        setShifts(shifts.map((shift, index) => ({ ...shift, id: index })));
      }
    }
    updateExcludedDaysVariable(shifts);
  }, [shifts]);

  const [selectedShiftId, setSelectedShiftId] = useState<number | null>(shifts.length > 0 ? shifts[0].id : null);

  useEffect(() => {
    if (selectedShiftId !== null) {
      const selectedShift = shifts.find((shift) => shift.id === selectedShiftId);
      if (selectedShift) {
        updateVariables(selectedShift);
      }
    }
  }, [selectedShiftId, shifts, updateVariables]);

  useEffect(() => {
    if (JSON.stringify(previousShiftsRef.current) !== JSON.stringify(shifts)) {
      onOptionsChange({ ...options, shifts });
      previousShiftsRef.current = shifts;
    }
  }, [shifts, onOptionsChange]);

  const applyTimeRange = (start: DateTime, end: DateTime) => {
    const timeFrom = start.valueOf();
    const timeTo = end.valueOf();
    onChangeTimeRange({ from: timeFrom, to: timeTo });
  };

  const convertTo24HourNumber = (date: DateTime): number => {
    const hours = date?.hour?.() ?? 0;
    const minutes = date?.minute?.() ?? 0;
    return hours + minutes / 60;
  };
  

  const handleShiftClick = (shiftId: number) => {
    setSelectedShiftId(shiftId);
  };

  const handleAddShift = () => {
    const newShift: Shift = {
      id: shifts.length,
      startTime: dateTime(),
      endTime: dateTime().add(8, 'hours'),
      daysOfWeek: [],
    };
    setShifts([...shifts, newShift]);
    updateExcludedDaysVariable([...shifts, newShift]);
  };

  const handleRemoveShift = (shiftId: number) => {
    const updatedShifts = shifts.filter((s) => s.id !== shiftId);
    setShifts(updatedShifts);
    updateExcludedDaysVariable(updatedShifts);
  };

  const handleStartTimeChange = (shiftId: number, newStartTime: DateTime) => {
    const updatedShifts = shifts.map((shift: Shift) => {
      if (shift.id === shiftId) {
        const updatedShift = { ...shift, startTime: dateTime(newStartTime) };
        if (shift.id === selectedShiftId) {
          updateVariables(updatedShift);
        }
        return updatedShift;
      }
      return shift;
    });
    setShifts(updatedShifts);
    updateExcludedDaysVariable(updatedShifts);
  };

  const handleEndTimeChange = (shiftId: number, newEndTime: DateTime) => {
    const updatedShifts = shifts.map((shift: Shift) => {
      if (shift.id === shiftId) {
        let adjustedEndTime = dateTime(newEndTime);
        if (newEndTime.valueOf() < shift.startTime.valueOf()) {
          adjustedEndTime = adjustedEndTime.add(1, 'day');
        }
        const updatedShift = { ...shift, endTime: adjustedEndTime };
        if (shift.id === selectedShiftId) {
          updateVariables(updatedShift);
        }
        return updatedShift;
      }
      return shift;
    });
    setShifts(updatedShifts);
    updateExcludedDaysVariable(updatedShifts);
  };

  const handleDaysOfWeekChange = (shiftId: number, selectedDays: any) => {
    const updatedShifts = shifts.map((shift: Shift) => {
      if (shift.id === shiftId) {
        return { ...shift, daysOfWeek: selectedDays.map((day: any) => day.value) };
      }
      return shift;
    });
    setShifts(updatedShifts);
    updateExcludedDaysVariable(updatedShifts);
  };

  const handleDateRangeChange = (value: Date | [Date, Date]) => {
    if (Array.isArray(value)) {
      const [start, end] = value.map(date => dateTime(date));
      setDateRange(value);
      applyTimeRange(start, end);
    } else {
      setDateRange([value, value]);
    }
  };

  const handleDownload = () => {
    if (!data || !data.series || data.series.length === 0 || !dateRange) {
      return;
    }
  
    const series = data.series[0];
    const runningThresholdStr = replaceVariables('$running_threshold');
    const runningThreshold = parseFloat(runningThresholdStr) || 0.5;
  
    let machineLabel = replaceVariables('${Machine:label}');
    console.log(machineLabel)
    machineLabel = machineLabel.split(':')[0];
  
    const datapoints: DataPoint[] = Array.from(series.fields[1].values).map((value, index) => ({
      timestamp: series.fields[0].values[index],
      value: value,
    }));
  
    const groupedData = datapoints.reduce<GroupedData>((acc, point) => {
      const date = new Date(point.timestamp).toISOString().split('T')[0];
      if (!acc[date]) {
        acc[date] = { aboveThreshold: 0, total: 0 };
      }
      acc[date].total += 1;
      if (point.value > runningThreshold) {
        acc[date].aboveThreshold += 1;
      }
      return acc;
    }, {});
  
    const csvData = Object.keys(groupedData).map(date => ({
      date,
      percentage: (groupedData[date].aboveThreshold / groupedData[date].total) * 100,
    }));
  
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
    const startDate = dateRange[0].toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
    const endDate = dateRange[1].toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
  
    const fileName = `${machineLabel}_${startDate}_${endDate}.csv`;
    saveAs(blob, fileName);
  };

  return (
    <VerticalGroup spacing="sm" className={css({ position: 'relative', zIndex: 0 })}>
      <Calendar
        selectRange
        onChange={(value) => handleDateRangeChange(value as Date | [Date, Date])}
        value={dateRange}
        className={css({ width: '100%', height: '100%' })}
      />
      {shifts.map((shift: Shift) => (
        <VerticalGroup key={shift.id} spacing="sm">
          <HorizontalGroup spacing="sm">
            <Button
              size="sm"
              variant={shift.id === selectedShiftId ? 'primary' : 'secondary'}
              onClick={() => handleShiftClick(shift.id)}
            >
              Shift {shift.id + 1}
            </Button>
            <TimeOfDayPicker
              size={'auto'}
              value={shift.startTime}
              onChange={(value) => handleStartTimeChange(shift.id, value)}
              showSeconds={false}
            />
            <TimeOfDayPicker
              size={'auto'}
              value={shift.endTime}
              onChange={(value) => handleEndTimeChange(shift.id, value)}
              showSeconds={false}
            />
            <IconButton
              aria-label="Remove"
              size="sm"
              name="trash-alt"
              variant="destructive"
              onClick={() => handleRemoveShift(shift.id)}
            >
              Remove
            </IconButton>
            <IconButton aria-label="Add" size="sm" name="plus" onClick={handleAddShift}>
              Add
            </IconButton>
          </HorizontalGroup>
          <MultiSelect
            options={daysOfWeekOptions}
            value={daysOfWeekOptions.filter((option) => shift.daysOfWeek.includes(option.value))}
            onChange={(selected) => handleDaysOfWeekChange(shift.id, selected)}
            placeholder="Exclude Days"
          />
        </VerticalGroup>
      ))}
      <Button fullWidth={true} onClick={handleDownload}>Download CSV</Button>
    </VerticalGroup>
  );
};
